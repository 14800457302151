import React from 'react';
import {Button} from 'devextreme-react';
import {confirm} from 'devextreme/ui/dialog';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {useHistory} from 'react-router-dom';
import {vars} from 'utils/variables';

const {APP_CODES: {SUCCESS}, MERCHANT, NOTIFY} = vars;

const DetailButtons = ({
												 selectedAccountItem,
												 formData,
												 integrationFormData,
												 isReadOnly,
												 setIsReadOnly,
												 formState,
											 }) => {
	const {merchant, merchantFilterID} = formState;
	const history = useHistory();
	const handleClose = () => {
		if (merchant) {
			history.push(MERCHANT.PROFILES_PATH);
		} else {
			history.replace(MERCHANT.PROFILES_PATH);
		}
	};
	// TODO: save, close
	return (
		<div className={'details-buttons'}>
			{isReadOnly ? (
				<>
					<Button
						type={'success'}
						onClick={() => {
							console.log('Редактировать');
							setIsReadOnly(false);
						}}
						text={'Редактировать'}
					/>
					<Button
						type={'danger'}
						onClick={() => {
							console.log('Удалить');
							const result = confirm("<i>Are you sure?</i>", "Confirm changes");
							result.then(async (dialogResult) => {
								//alert(dialogResult ? "Confirmed" : "Canceled");
								console.log(merchant.ID);
								console.log(merchant.MerchantID);
								if (dialogResult) {
									const deleteRequest = await apiRequest({
										operation: 'MerchantApiProfile/Del',
										data: {
											Params: {
												ID: merchant.ID,
												MerchantID: merchant.MerchantID,
											},
										},
									});

									if (deleteRequest.data.ResponseCode === SUCCESS) {
										handleClose();
									} else {
										notifyApp(deleteRequest.data.ResponseText);
									}
								}
							});
						}}
						text={'Удалить'}
					/>
				</>
			) : (
				<>
					<Button
						type={'default'}
						onClick={async () => {
							console.log('Сохранить');

							let data = {
								...formData,
								...integrationFormData,
							};

							const keys = [
								'BUEmail', 'CancelURL', 'Description', 'GeneralAccountID', 'ID', 'IsAllowCheckoutPayment',
								'IsAllowPayout', 'MerchantID', 'Name', 'NotifyURL', 'PayGateToken', 'SuccessURL', 'WebAddress',
								'HostPaymentPageURL', 'StatusOrderURL', 'ImageURL', 'CheckoutOrderText',
							];
							const modData = {};

							keys.forEach((item) => {
								modData[item] = data[item];
							});

							modData.GeneralAccountID = selectedAccountItem.ID;
							modData.MerchantID = merchantFilterID;
							modData.IsAllowCheckoutPayment = + data.IsAllowCheckoutPayment;
							modData.IsAllowPayout = + data.IsAllowPayout;

							console.group('Save all data');
							console.log(modData);
							console.groupEnd();

							const request = await apiRequest({
								operation: `MerchantApiProfile/${merchant ? 'Mod' : 'Ins'}`,
								data: {
									Params: modData,
								},
							});

							if (request && request.data.ResponseCode === SUCCESS) {
								merchant ? setIsReadOnly(true) : handleClose();
								notifyApp('Operation successful', NOTIFY.SUCCESS);
							} else {
								notifyApp(request.data.ResponseText);
							}
						}}
						text={'Сохранить'}
					/>
					<Button
						type={'danger'}
						onClick={() => {
							console.log('Отменить');
							setIsReadOnly(true);
						}}
						text={'Отменить'}
					/>
				</>
			)}
			<Button
				type={'normal'}
				onClick={() => {
					console.log('Закрыть');
					handleClose();
				}}
				text={'Закрыть'}
			/>
		</div>
	);
};

export default DetailButtons;
