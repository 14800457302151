import React from 'react';
import {Button} from 'devextreme-react';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';

const {APP_CODES: {SUCCESS}, MERCHANT, NOTIFY} = vars;

const ProfileParamsDetailButtons = ({
																			localPaymentSystemId,
																			profileParamsRef,
	 																		merchantApiProfileID,
																			isEditingMode,
																			togglePage,
																		}) => {

	return (
		<div className={'details-buttons'}>
			<Button
				text={'Сохранить'}
				type={'default'}
				onClick={async () => {
					console.log('try save');
					const formData = profileParamsRef.current.instance.option('formData');
					
					const IsAllowCheckoutPayout = +formData.IsAllowCheckoutPayout || 0;
					const IsAllowCheckoutIncome = +formData.IsAllowCheckoutIncome || 0;
					const IsInactive = +formData.IsInactive || 0;
					
					const requestData = {
						MerchantApiProfileID: merchantApiProfileID,
						PaymentSystemTypeID: localPaymentSystemId,
						IsAllowCheckoutPayout,
						IsAllowCheckoutIncome,
						IsInactive,
						CryptoIncomeProcessType: formData.CryptoIncomeProcessType || null,
						IndividualRollingReserveRate: formData.IndividualRollingReserveRate || null,
						IndividualRollingReserveDays: formData.IndividualRollingReserveDays || null,
						IsPayRetailersPaywall: +formData.IsPayRetailersPaywall || 0,
						CurrencyIDs: formData.CurrencyIDs || null,
						IsAllowPayoutFromDefaultAccount: +formData.IsAllowPayoutFromDefaultAccount || 0,
					}

					const request = await apiRequest({
						operation: `MerchantApiProfilePaymentSystemType/${isEditingMode ? 'Mod' : 'Ins'}`,
						data: {
							Params: requestData,
						},
					});

					if (request && request.data.ResponseCode === SUCCESS) {
						notifyApp('Operation successful', NOTIFY.SUCCESS);
						togglePage(null);
					} else {
						notifyApp(request.data.ResponseText);
					}
				}}
			/>
			<Button
				text={'Закрыть'}
				type={'normal'}
				onClick={() => {
					console.log('open previous page');
					togglePage(null);
				}}
			/>
		</div>
	);
};

export default ProfileParamsDetailButtons;
