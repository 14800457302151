import React, {useEffect, useRef, useState} from 'react';
import Form, {GroupItem, SimpleItem} from 'devextreme-react/form';
import DataGrid, {Button as GridButton, Column} from 'devextreme-react/data-grid';
import {notifyApp} from 'utils/notifyWrapper';
import {confirm} from 'devextreme/ui/dialog';
import DetailButtons from './detail-buttons';
import Button from 'devextreme-react/button';
import Loader from 'components/loader/loader';
import Collapsible from 'react-collapsible';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';
import './profile-details-form.scss';

const {APP_CODES: {SUCCESS}, EDITOR_OPTIONS} = vars;

const ProfileDetailsForm = ({formState, togglePage}) => {
	console.log('formState');
	console.log(formState);
	const {merchantFilterID, merchant} = formState;
	const [isReadOnly, setIsReadOnly] = useState(true);
	const [formData, setFormData] = useState(null);
	const [integrationFormData, setIntegrationFormData] = useState(null);
	const [gridItems, setGridItems] = useState([]);
	const [isReady, setIsReady] = useState(false);
	const [accountItems, setAccountItems] = useState(null);
	const [selectedAccountItem, setSelectedAccountItem] = useState({ID: null, Code: null});
	const profileRef = useRef();
	const apiKeyRef = useRef();
	const paymentParamsGridRef = useRef(null);
	const formProps = {
		showColonAfterLabel: true,
		labelLocation: 'left',
	};
	
	const loadAccountSelectData = async () => {
		const accountForSelectBox = await apiRequest({
			operation: 'Account/List',
			data: {
				Filters: {
					MerchantID: {'=': merchantFilterID},
					PaymentSystemTypeID: {'=': 0},
					AccountTypeID: {'=': 1},
				},
				Sorts: ['-ID'],
			},
		});
		
		if (accountForSelectBox.data.ResponseCode === SUCCESS) {
			return Promise.resolve(accountForSelectBox.data.Response.Account);
		} else {
			return Promise.reject(accountForSelectBox.data.ResponseText);
		}
	};
	
	const loadApiKeys = async () => {
		const apiKeysRequest = await apiRequest({
			operation: 'MerchantApiProfile/GetApiKeys',
			data: {
				Params: {ID: merchant ? merchant.ID : null},
			},
		});
		
		if (apiKeysRequest.data.ResponseCode === SUCCESS) {
			return Promise.resolve(apiKeysRequest.data.Response);
		} else {
			return Promise.reject(apiKeysRequest.data.ResponseText);
		}
	};
	
	const loadFormData = async () => {
		let merchantData = {};
		
		if (!merchant) {
			return Promise.resolve(null);
		}
		
		const merchantApiProfilesRequest = await apiRequest({
			operation: 'MerchantApiProfile/List',
			data: {
				Filters: {ID: merchant ? merchant.ID : null},
			},
		});
		
		if (merchantApiProfilesRequest.data.ResponseCode === SUCCESS) {
			Object.assign(merchantData, merchantApiProfilesRequest.data.Response.MerchantApiProfile[0]);
			return Promise.resolve(merchantData);
		} else {
			return Promise.reject(merchantApiProfilesRequest.data.ResponseText);
		}
	};
	
	const initPageLoading = () => {
		let data = {};
		
		loadFormData().then((merchantData) => {
			if (merchantData) {
				if (merchantData && merchantData.GeneralAccountCode && merchantData.GeneralAccountID) {
					setSelectedAccountItem({ID: merchantData.GeneralAccountID, Code: merchantData.GeneralAccountCode});
				}
				
				data = merchantData;
				
				setFormData({
					...merchantData,
					IsAllowCheckoutPayment: !!merchantData.IsAllowCheckoutPayment,
					IsAllowPayout: !!merchantData.IsAllowPayout,
				});
				
				return true;
			}
			setFormData({
				IsAllowCheckoutPayment: false,
				IsAllowPayout: false,
				GeneralAccountCode: null,
			});
			return false;
		}).then((result) => {
			console.group('profile-details-resolve');
			console.log(result);
			console.groupEnd();
			setIsReadOnly(result);
			return result;
		}).then(async (result) => {
			// load api keys
			let apiKeys = {};
			
			if (merchant) {
				try {
					apiKeys = await loadApiKeys();
					
				} catch (errorText) {
					notifyApp(errorText);
				}
			}
			
			return result ? {
				IsAllowCheckoutPayment: data.IsAllowCheckoutPayment,
				IsAllowPayout: data.IsAllowPayout,
				PayGateToken: data.PayGateToken,
				...apiKeys,
			} : null;
		}).then((integrationData) => {
			// set loaded data
			console.group('api keys');
			console.log(integrationData);
			console.groupEnd();
			setIntegrationFormData(integrationData);
		}).catch((error) => {
			console.group('profile-details-reject');
			console.error(error);
			console.groupEnd();
			notifyApp(error);
		}).finally(() => {
			setIsReady(true);
		});
	}
	
	useEffect(() => {
		console.group('useEffect');
		console.log(merchant);
		console.groupEnd();
		initPageLoading();
	}, []);
	
	const loadGridData = async () => {
		const request = await apiRequest({
			operation: 'MerchantApiProfilePaymentSystemType/List',
			data: {
				Columns: [
					'ID',
					'PaymentSystemTypeID',
					'Name',
					'IsAllowCheckoutIncome',
					'IsAllowCheckoutPayout',
					'IndividualRollingReserveRate',
					'GeneralRollingReserveRate',
					'IndividualRollingReserveDays',
					'GeneralRollingReserveDays',
					'CryptoIncomeProcessType',
					'IsPayRetailersPaywall',
					'IsAllowPayoutFromDefaultAccount',
					'CurrencyIDs',
				],
				Filters: {
					MerchantApiProfileID: merchant ? merchant.ID : null,
				},
				Sorts: ['Name'],
				Page: -1,
			},
		});
		
		if (request.data.ResponseCode === SUCCESS) {
			const origin = request.data.Response.MerchantApiProfilePaymentSystemType;
			if (origin.length > 0) {
				
				origin.forEach((item) => {
					origin.IsAllowCheckoutIncome = !!item.IsAllowCheckoutIncome;
					origin.IsAllowCheckoutPayout = !!item.IsAllowCheckoutPayout;
					origin.IsAllowPayoutFromDefaultAccount = !!item.IsAllowPayoutFromDefaultAccount;
					origin.IsPayRetailersPaywall = !!item.IsPayRetailersPaywall;
				});
			}
			
			return Promise.resolve(origin);
		} else {
			return Promise.reject(request.data.ResponseText);
		}
	};
	
	useEffect(() => {
		console.group('useEffectmerchantFilterID');
		console.log(merchantFilterID);
		console.groupEnd();
		loadAccountSelectData().then((accounts) => {
			setAccountItems(accounts);
		}).then(() => {
			console.group('datas');
			console.log(merchant);
			console.groupEnd();
			// merchant.MerchantID
			return loadGridData();
		}).then((gridData) => {
			console.group('grid data');
			console.log(gridData);
			console.groupEnd();
			setGridItems(gridData);
		}).catch((error) => {
			notifyApp(error);
		});
	}, [merchantFilterID]);
	
	const isPaymentSystemAddButtonDisabled = () => {
		return !merchant ? true : isReadOnly;
	}
	
	return (
		<div id={'profile-detail-form-wrapper'}>
			<Loader isShowLoader={!isReady}/>
			<DetailButtons
				selectedAccountItem={selectedAccountItem}
				formData={formData}
				integrationFormData={integrationFormData}
				formState={formState}
				setIsReadOnly={setIsReadOnly}
				isReadOnly={isReadOnly}
			/>
			<Form
				ref={profileRef}
				formData={formData}
				readOnly={isReadOnly}
				{...formProps}
			>
				<GroupItem caption="Профиль">
					<SimpleItem dataField={'Name'} label={{text: 'Имя'}}/>
					<SimpleItem dataField={'MerchantApiProfileStatusName'} editorOptions={EDITOR_OPTIONS.disabled}
											label={{text: 'Статус'}}/>
					<SimpleItem dataField={'HostPaymentPageURL'} label={{text: 'URL платежной страницы'}} helpText={() => {
						return formData ? 'По-умолчанию: ' + formData['CfgHostPaymentPageURL'] : '';
					}}/>
					<SimpleItem dataField={'StatusOrderURL'} label={{text: 'URL статусной станицы'}} helpText={() => {
						return formData ? 'По-умолчанию: ' + formData['CfgStatusOrderURL'] : '';
					}}/>
					<SimpleItem dataField={'NotifyURL'} label={{text: 'URL для уведомления'}}/>
					<SimpleItem dataField={'SuccessURL'} label={{text: 'URL для успешного платежа'}}/>
					<SimpleItem dataField={'CancelURL'} label={{text: 'URL для отмененного платежа'}}/>
					<SimpleItem dataField={'Description'} label={{text: 'Описание'}}/>
					<SimpleItem dataField={'WebAddress'} label={{text: 'Адрес сайта'}}/>
					<SimpleItem dataField={'BUEmail'} label={{text: 'Email для отображения клиента'}}/>
					<SimpleItem dataField={'ImageURL'} label={{text: 'Логотип мерчанта'}}/>
					<SimpleItem dataField={'CheckoutOrderText'} label={{text: 'Тексты для CheckoutOrder'}}/>
				</GroupItem>
			</Form>
			<Collapsible trigger={'Основные параметры'}>
				<div className={'accordion-form'}>
					{formData ? (
						<Form
							ref={apiKeyRef}
							formData={integrationFormData}
							readOnly={isReadOnly}
							{...formProps}
						>
							<SimpleItem
								dataField={'IsAllowCheckoutPayment'}
								label={{text: 'Разрешена оплата'}}
								editorType={'dxCheckBox'}
								editorOptions={{
									isReadOnly: isReadOnly,
									onValueChanged: ({value}) => {
										setIntegrationFormData({
											...integrationFormData,
											IsAllowCheckoutPayment: value,
										});
									},
								}}
							/>
							<SimpleItem
								dataField={'IsAllowPayout'}
								label={{text: 'Разрешена выплата'}}
								editorType={'dxCheckBox'}
								editorOptions={{
									isReadOnly: isReadOnly,
									onValueChanged: ({value}) => {
										setIntegrationFormData({
											...integrationFormData,
											IsAllowPayout: value,
										});
									},
								}}
							/>
							<SimpleItem
								label={{text: 'Основной счет'}}
								editorType={'dxSelectBox'}
								editorOptions={{
									dataSource: accountItems,
									displayExpr: 'Code',
									valueExpr: 'ID',
									value: selectedAccountItem.ID,
									onValueChanged: ({value}) => {
										const selectedAccount = accountItems.find((item) => item.ID === value);
										setSelectedAccountItem(selectedAccount);
									},
								}}
							/>
							<SimpleItem dataField={'APIKey'} label={{text: 'Токен мерчанта'}}
													editorOptions={EDITOR_OPTIONS.readOnly}/>
							<SimpleItem dataField={'NotifySignKey'} label={{text: 'Ключ подписи'}}
													editorOptions={EDITOR_OPTIONS.readOnly}/>
							<SimpleItem dataField={'PayGateToken'} label={{text: 'Токен для Шлюза'}}
													editorOptions={{isReadOnly: isReadOnly}}/>
						</Form>
					) : null}
				</div>
			</Collapsible>
			<Collapsible trigger={'Платежные параметры'}>
				<div className={'accordion-form'}>
					<div className={'dx-form-group-with-caption dx-form-group'}>
						<Button
							className={'accordion-item'}
							disabled={isPaymentSystemAddButtonDisabled()}
							text={'Добавить'}
							onClick={() => {
								togglePage({
									isEditingMode: false,
									merchantApiProfilePaymentSystemType: null,
								});
							}}
						/>
						<DataGrid
							ref={paymentParamsGridRef}
							dataSource={gridItems}
							keyExpr={'PaymentSystemTypeID'}
							disabled={isReadOnly}
						>
							<Column dataField={'Name'} dataType={'string'} caption={'Название ПС'}/>
							<Column dataField={'IsAllowCheckoutIncome'} dataType={'boolean'} caption={'Разрешена оплата'}/>
							<Column dataField={'IsAllowCheckoutPayout'} dataType={'boolean'} caption={'Разрешена выплата'}/>
							<Column
								dataField={'IndividualRollingReserveRate'}
								dataType={'number'}
								caption={'RollingReserveRate'}
								cellRender={({data: {IndividualRollingReserveRate, GeneralRollingReserveRate}}) => {
									return IndividualRollingReserveRate ? IndividualRollingReserveRate : GeneralRollingReserveRate;
								}}
							/>
							<Column
								dataField={'IndividualRollingReserveDays'}
								dataType={'number'}
								caption={'RollingReserveDays'}
								cellRender={({data: {IndividualRollingReserveDays, GeneralRollingReserveDays}}) => {
									return IndividualRollingReserveDays ? IndividualRollingReserveDays : GeneralRollingReserveDays;
								}}
							/>
							<Column type={'buttons'}>
								<GridButton
									hint={'Редактировать'}
									icon={'edit'}
									onClick={({row: {data}}) => {
										togglePage({
											isEditingMode: true,
											merchantApiProfilePaymentSystemType: data,
										});
									}}
								/>
								<GridButton
									hint={'Удалить'}
									icon={'close'}
									onClick={async ({row: {data: {PaymentSystemTypeID}}}) => {
										const isApproved = await confirm("<i>Are you sure?</i>", "Confirm changes");
										
										if (isApproved) {
											const deleteRequest = await apiRequest({
												operation: 'MerchantApiProfilePaymentSystemType/Del',
												data: {
													Params: {
														MerchantApiProfileID: merchant ? merchant.ID : null,
														PaymentSystemTypeID: PaymentSystemTypeID,
													},
												},
											});
											
											if (deleteRequest.data.ResponseCode === SUCCESS) {
												loadGridData().then((gridData) => {
													setGridItems(gridData);
												}).catch((errorText) => {
													notifyApp(errorText)
												});
											} else {
												notifyApp(deleteRequest.data.ResponseText);
											}
										}
									}}
								/>
							</Column>
						</DataGrid>
					</div>
				</div>
			</Collapsible>
		</div>
	);
};

export default ProfileDetailsForm;
